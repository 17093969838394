import React from 'react';
// import { useStaticQuery, graphql } from 'gatsby';

// components
import Layout from '@src/layouts';
import Link from '@components/atoms/Link';
// import ButtonPrimary from '@components/atoms/buttons/ButtonPrimary';
import SectionTitle from '@components/atoms/SectionTitle';
// import Cta from '@components/molecules/Cta';
import HeroJob from '@components/molecules/heros/HeroJob';
// import ListCards from '@components/organisms/ListCards';
// import ListTeam from '@components/organisms/ListTeam';

// assets
// import imgBrandingStrategy from '@assets/images/ico-branding-brand-positioning.svg';

// --------------------------------
// #region data
// --------------------------------

const language = 'fr';

const pageClass = 'page-job';

const footerRelatedLinks = [
	{ title: 'L’Équipe', url: '/fr/equipe/' },
	{ title: 'Culture', url: '/fr/culture/' },
];

// const cardsList = [
// 	{
// 		image: imgBrandingStrategy,
// 		title: 'Positionnement',
// 		text:
// 			"Positionnement et définition de la personnalité d'une marque par l'intermédiaire de workshops.",
// 	},
// 	{
// 		image: imgBrandingStrategy,
// 		title: 'Création de logo',
// 		text:
// 			"Conception et dessin d'un sigle permettant d'être reconnu au sein de votre marché.",
// 	},
// 	{
// 		image: imgBrandingStrategy,
// 		title: 'Motion Design',
// 		text:
// 			"Création d'animations et de micro-interactions pour les supports digitaux de votre marque.",
// 	},
// 	{
// 		image: imgBrandingStrategy,
// 		title: 'Identité visuelle',
// 		text:
// 			'Création des éléments graphiques et de supports permettant à votre marque de se rendre visible.',
// 	},
// 	{
// 		image: imgBrandingStrategy,
// 		title: 'Illustration',
// 		text:
// 			"Réalisation d'un univers visuel propre à votre marque. De l'illustration à la définition d'une iconographie en adéquation avec vos besoins.",
// 	},
// 	{
// 		image: imgBrandingStrategy,
// 		title: 'UI – User Interface',
// 		text:
// 			"Création de l’interface  digitale où l'animation, l'illustration, la typographie et autres éléments visuels composent un ensemble cohérent.",
// 	},
// ];

// --------------------------------
// #endregion
// --------------------------------

const JobDetailPage = (props) => {
	// const data = useStaticQuery(
	// 	graphql`
	// 		query {
	// 			teamImages: allFile(
	// 				filter: {
	// 					relativePath: {
	// 						in: [
	// 							"team/angela.jpg"
	// 							"team/hugo.jpg"
	// 							"team/luis.jpg"
	// 							"team/felipe.jpg"
	// 						]
	// 					}
	// 				}
	// 			) {
	// 				edges {
	// 					node {
	// 						relativePath
	// 						name
	// 						childImageSharp {
	// 							id
	// 							fluid(
	// 								maxWidth: 228
	// 								quality: 90
	// 								traceSVG: {
	// 									color: "#1f1f1f"
	// 									background: "#dddddd"
	// 								}
	// 							) {
	// 								...GatsbyImageSharpFluid_withWebp_noBase64
	// 							}
	// 						}
	// 					}
	// 				}
	// 			}
	// 		}
	// 	`
	// );

	// data.getImageAttributes = function (dataKey, relativePath) {
	// 	const imageAttributes = this[dataKey]['edges'].find(
	// 		({ node }) => node.relativePath === relativePath
	// 	).node.childImageSharp;

	// 	return imageAttributes;
	// };

	// const teamMembers = [
	// 	{
	// 		name: 'Ângela',
	// 		role: 'Frontend Developer',
	// 		image: data.getImageAttributes('teamImages', 'team/angela.jpg'),
	// 		link: '/todo',
	// 	},
	// 	{
	// 		name: 'Luis',
	// 		role: 'Frontend Developer',
	// 		image: data.getImageAttributes('teamImages', 'team/luis.jpg'),
	// 		link: '/todo',
	// 	},
	// 	{
	// 		name: 'Hugo',
	// 		role: 'Partner, Fullstack Developer',
	// 		image: data.getImageAttributes('teamImages', 'team/hugo.jpg'),
	// 		link: '/todo',
	// 	},
	// 	{
	// 		name: 'Felipe',
	// 		role: 'Fullstack Developer',
	// 		image: data.getImageAttributes('teamImages', 'team/felipe.jpg'),
	// 		link: '/todo',
	// 	},
	// ];

	return (
		<Layout
			language={language}
			pageClass={pageClass}
			footerRelatedLinks={footerRelatedLinks}
			location={props.location}
			translations={[{ uri: '/en/jobs/backend-developer/', lang: 'en' }]}
			title="Rejoins superhuit en tant que Tech Lead à Lausanne"
			seo={{
				description: `Représenter la compétence technique de l’agence, à l’interne comme à l’externe, et porter son évolution sur le plan technique.`,
			}}
		>
			<HeroJob
				breadcrumb={{ title: 'Postes ouverts', link: '/fr/rejoindre/' }}
				title="Tech Lead"
				location="Lausanne, Suisse"
				anchors={[
					// {
					// 	title: 'In Short',
					// 	href: '#brief',
					// },
					{
						title: 'Profil recherché',
						href: '#you-are',
					},
					{
						title: 'Nous sommes',
						href: '#we-are',
					},
				]}
			/>
			<section
				className="grid text-content text-center"
				aria-label="Introduction"
			>
				<h2 data-animation-page className="col-big">
					Représenter la compétence technique de l’agence, à l’interne
					comme à l’externe, et porter son évolution sur le plan
					technique.
				</h2>
			</section>

			{/* <section
				id="brief"
				className="grid grid-content"
				aria-label="Brief"
				data-animation-page
			>
				<ListCards modifiers="zigzag" cardsList={cardsList} />
			</section> */}

			<section
				id="you-are"
				className="grid grid-content"
				aria-label="Compétences &amp; Personnalité"
			>
				<div className="box text-content" data-animation-page>
					<p className="uptitle">Compétences &amp; Personnalité</p>
					<div className="list-two-columns">
						<div className="text-content">
							<h3>Profil recherché</h3>
							<p>
								Nous cherchons une personne qui a à coeur le web
								et l’innovation et qui voudrait faire progresser
								l’agence dans sa compétence tech et son offre
								digitale, en collaboration avec l’équipe (en or)
								dont 4 dev senior.
							</p>
							<p>
								Forte compétence technique, permettant d’être un
								soutien stratégique à l’équipe sur des sujets
								complexes et de représenter la crédibilité de
								l’agence auprès de prospects et clients.
							</p>
							<p>
								Donne beaucoup d’importance à la qualité, un
								objectif majeur est d’être toujours plus fiers
								de nos créations.
							</p>
							<p>
								Intérêt pour les univers de la marque, du
								business, de la stratégie, de l’innovation.
							</p>
							<h3>Perspectives</h3>
							<ul className="list-default">
								<li>
									Faire pleinement partie de l’équipe de
									développement et impliqué-e dans les
									projets, tout en assurant la direction
									technique de l’agence.
								</li>
								<li>
									L’agence c’est avant tout des humains
									talentueux et en progression: soutenir
									l’équipe dev dans son évolution et être
									garant-e de la pertinence de la réponse
									technique (qualité, efficience, choix
									techniques).
								</li>
								<li>
									Collaborer étroitement sur la stratégie de
									progression de l’agence avec les associés:
									recrutement, évolution commerciale,
									rentabilité du développement.
								</li>
								<li>
									Assurer un répondant fiable, crédible et
									ambitieux auprès des clients et de l'équipe
									(associés, client partners, designers,
									developers). Représenter l’agence auprès des
									prospects et clients dans le cadre d’appels
									d’offres et pitch.
								</li>
								<li>
									Identifier les opportunités d’innovation et
									de différenciation pour l’entreprise et
									collaborer avec l’équipe dev senior pour
									pousser la qualité, l’efficience de nos
									créations.
								</li>
							</ul>
						</div>
						<div className="text-content">
							<h3>Compétences requises</h3>
							<ul className="list-default">
								<li>
									Au moins 3 ans en tant que développeur-se
									senior et/ou tech lead en agence web
								</li>
								<li>
									Expérience en JavaScript/Node.js,
									React/Next.js, PHP/WordPress, un gros plus
								</li>
								<li>
									Une excellente compréhension stratégique du
									fonctionnement du web et de ses enjeux
									(hosting, performance, SEO, choix
									technologiques, DX, sécurité,…)
								</li>
								<li>
									Savoir analyser la faisabilité technique
									d'un projet, définir une vision et en
									estimer la réalisation
								</li>
								<li>
									Excellentes compétences interpersonnelles et
									de communication
								</li>
								<li>
									Un niveau professionnel parlé et écrit en
									français et anglais
								</li>
							</ul>
						</div>
					</div>
				</div>
			</section>

			<section id="we-are" aria-label="Superhuit">
				<SectionTitle title="Travaillons ensemble" />

				<div className="grid">
					<div className="box text-content" data-animation-page>
						<p className="uptitle">Superhuit</p>
						<h2>Travaillons ensemble</h2>
						<div className="list-two-columns">
							<div className="text-content">
								<h4>Nous sommes</h4>
								<p>
									Une agence reconnue pour la création de
									stratégies mesurables, de marques
									puissantes, de designs ambitieux et de
									solutions web attrayantes.
								</p>
								<p>
									La collaboration est au cœur de notre
									démarche centrée sur l’utilisateur, et nous
									accompagnons nos clients à chaque étape de
									leur transformation. Nous sommes constamment
									à la recherche de projets innovants et
									variés sur lesquels notre équipe de
									passionné-e-s aime travailler.
								</p>
								<p>
									Bien que l'expérience soit un aspect
									fondamental lors du recrutement, nous
									accordons une grande valeur à la curiosité
									et à la volonté d'apprendre. Notre volonté
									est de grandir tout en maintenant une équipe
									à taille humaine.
								</p>
							</div>
							<div className="text-content">
								<h4>Nous offrons</h4>

								<ul className="list-default">
									<li>
										Une culture d’équipe fondamentalement
										positive, curieuse, bienveillante et de
										confiance. Ouverte à explorer de
										nouveaux terrains.
									</li>
									<li>
										Une collaboration étroite et stratégique
										avec les associés et l’équipe pour
										dessiner le futur de l’agence.
									</li>
									<li>
										Nous donnons beaucoup d'importance au
										feedback régulier, nous nous essayons à
										de nouvelles façons de faire et de
										penser, nous soutenons la montée en
										compétence au travers de formations, de
										temps de partage et de coaching
										individuel et d'équipe. L’entreprise est
										un terrain de progression personnelle,
										elle est au service de l’humain et non
										l’inverse.
									</li>
									<li>
										Flexibilité dans la gestion du temps de
										travail, dans le taux de travail 80-100%
										et le remote est courant dans l’équipe
										(libre mais au minimum 2j/semaine à
										l’agence, pour apprendre ensemble et
										garder un esprit d’équipe fort).
									</li>
									<li>
										Chacun-e contribue au développement de
										l'agence, les bénéfices sont partagés au
										sein de l'équipe.
									</li>
									<li>
										Des projets variés et qualitatifs pour
										des clients renommés locaux et à
										l’international: Sites d’expérience ou
										plus corpo (Next.js, Headless
										WordPress), Produit web (exemple récent:
										refonte UX, UI et frontend d’un ERP en
										React), Headless e-commerce, Intégration
										IA — exemple récent: solution RAG
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>

				{/* <Cta
					text="Want to join us in Porto?"
					cta="Apply now"
					url="/todo"
				/> */}
			</section>

			<section aria-label="Postuler" className="grid" id="apply">
				<div
					className="text-center box box--no-border text-content"
					data-animation-page
				>
					<h2>Intéressé·e?</h2>
					<p>
						<span
							style={{ display: 'inline-block', maxWidth: 500 }}
						>
							Écrivez-nous à{' '}
							<Link
								href="mailto:job-dev@superhuit.ch"
								style={{ whiteSpace: 'pre' }}
							>
								job-dev@superhuit.ch
							</Link>{' '}
							et montrez-nous les projets qui font votre fierté.
						</span>
					</p>
					{/* <p>
						<span
							style={{ display: 'inline-block', maxWidth: 500 }}
						>
							Dis-nous également ce que tu attends de ce poste,
							une compétence que tu maîtrises, une compétence que
							tu souhaites développer et ce que tu penses de
							l'état actuel du web
						</span>
					</p> */}
				</div>
			</section>
		</Layout>
	);
};

export default JobDetailPage;
